import request from '../axios'

/**
 * 获取关注的专项政治监督分页列表
 */
export function getSpecialAttentionPage(params) {
  return request({
    url: '/api/special/users/follow/list',
    params,
  })
}

/**
 * 获取部门接收到的专项政治监督分页列表
 */
export function getSpecialReceivePage(params) {
  return request({
    url: '/api/special/tasks/list',
    params,
  })
}

// 获取创建的列表
export function getSpecialPage(params) {
  return request({
    url: '/api/special',
    params,
  })
}

/**
 * 获取专项政治监督详情
 */
export function getSpecial(id) {
  return request({
    url: `/api/special/${id}`,
  })
}

/**
 * 新建专项政治监督
 */
export function createSpecial(params) {
  return request({
    method: 'post',
    url: '/api/special',
    data: params,
  })
}

/**
 * 更新专项政治监督
 */
export function updateSpecial(id, params) {
  return request({
    method: 'post',
    url: `/api/special/${id}`,
    data: params,
  })
}

/**
 * 删除专项政治监督
 */
export function deleteSpecial(id) {
  return request({
    method: 'post',
    url: `/api/special/delete/${id}`,
  })
}

/**
 * 获取文章分类
 */
export function getAllColumn(params) {
  return request({
    url: '/api/special/all/column',
    params,
  })
}

/**
 * 获取文章列表
 */
export function getSpecialActionPage(params) {
  return request({
    url: '/api/special/content/list',
    params,
  })
}

/**
 *  获取文章详情
 */
export function getSpecialContent(params) {
  return request({
    url: `/api/special/content/find/${params.id}`,
    params,
  })
}

/**
 * 添加文章
 */
export function createSpecialAction(params) {
  return request({
    method: 'post',
    url: '/api/special/content/add',
    data: params,
  })
}

/**
 *  修改文章
 */
export function updateSpecialAction(id, params) {
  return request({
    method: 'post',
    url: `/api/special/content/update/${id}`,
    data: params,
  })
}

/**
 *  获取全部标签
 */
export function getAllLabel(params) {
  return request({
    method: 'get',
    url: '/api/special/label/all',
    data: params,
  })
}

/**
 * 删除专项政治监督落实
 */
export function deleteSpecialAction(id) {
  return request({
    method: 'post',
    url: `/api/special/content/del/${id}`,
  })
}

/**
 * 获取文章点赞用户列表
 */
export function getActionFabulousUsers(id) {
  return request({
    url: `/api/special/content-fabulous/all/user/${id}`,
  })
}

/**
 * 检测用户点赞状态
 */
export function getActionFabulousUsersStatus(id) {
  return request({
    url: `/api/special/content-fabulous/state/${id}`,
  })
}

/**
 * 点赞、取消点赞
 */
export function updateActionFabulousUsers(id) {
  return request({
    method: 'post',
    url: `/api/special/content-fabulous/${id}`,
  })
}

/**
 * 获取评论列表
 */
export function getActionCommentList(params) {
  return request({
    url: '/api/special/content-comment/list',
    params,
  })
}

/**
 * 新增评论
 */
export function createActionComment(params) {
  return request({
    method: 'post',
    url: '/api/special/content-comment/add',
    data: params,
  })
}

/**
 * 修改评论
 */
export function updateActionComment(id, params) {
  return request({
    method: 'post',
    url: `/api/special/content-comment/update/${id}`,
    data: params,
  })
}

/**
 * 删除评论
 */
export function deleteActionComment(id) {
  return request({
    method: 'post',
    url: `/api/special/content-comment/del/${id}`,
  })
}
// 上传汇总表
export function createHuizong(params) {
  return request({
    method: 'post',
    url: `api/special/huizong/create`,
    data: params,
  })
}
// 删除
export function deleteHuizong(id) {
  return request({
    method: 'post',
    url: `/api/special/huizong/delete/${id}`,
  })
}
